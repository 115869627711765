import React from "react";

import TopHeader from "../components/commonComponents/TopHeader";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";

function BlogPage() {
  return (
    <>
      <div className="wrapper">
        {/* <!-- ========================  Header content ======================== --> */}

        {/* Top Header start */}
        <TopHeader />
        {/* Top Header end */}

        {/* logo & menu  start */}
        <Header />
        {/* logo & menu  end */}

        {/* ========================  Photo Gallery ======================== */}
        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cormorant sectionTitle text-white mt-4">
                  Blog{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section" data-aos="fade-up">
          {/*Header*/}
          {/* <header >
  <div class="container">
  <div class="cormorant sectionTitle">Our Services</div>
  
  </div>
    </header> */}
          {/*Main image*/}
          <div className="container">
            <div className="row">
              <div
                id="content"
                className="col-xs-12 col-sm-7 col-md-8 col-lg-8 col-sm-push-5 col-md-push-4 col-lg-push-4"
              >
                <article
                  id="post-2768"
                  className="vertical-item content-padding big-padding with_background overflow-hidden post-2768 post type-post status-publish format-video has-post-thumbnail hentry category-uncategorized post_format-post-format-video"
                >
                  <div className="item-media-wrap">
                    <div className="item-media entry-thumbnail post-thumbnail">
                      <div className="entry-meta-corner">
                        <span className="date">
                          <time
                            className="entry-date"
                            dateTime="2017-08-11T13:52:21+00:00"
                          >
                            <strong>11</strong>Aug
                          </time>
                        </span>
                      </div>
                      <div className="embed-responsive embed-responsive-3by2">
                        <a
                          href=""
                          data-iframe='<iframe width="1170" height="658" src="https://www.youtube.com/embed/mcixldqDIEQ?feature=oembed" frameborder="0" allowfullscreen></iframe>'
                          className="embed-placeholder"
                        >
                          <img
                            width={1170}
                            height={780}
                            src="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2.jpg"
                            className="attachment-comfort-full-width size-comfort-full-width wp-post-image"
                            alt=""
                            decoding="async"
                            fetchpriority="high"
                            srcSet="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2.jpg 1170w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-300x200.jpg 300w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-768x512.jpg 768w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-1024x683.jpg 1024w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-775x517.jpg 775w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-600x400.jpg 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />{" "}
                        </a>
                        {/* eof image link */}
                      </div>
                    </div>{" "}
                    {/* .item-media */}
                  </div>{" "}
                  {/* .item-media-wrap */}
                  <div className="item-content">
                    <div className="entry-header">
                      <h4 className="entry-title">
                        <a
                          href="https://webdesign-finder.com/comfort/cupim-bacon-short-ribs-picanha/"
                          rel="bookmark"
                        >
                          Cupim bacon short ribs picanha
                        </a>
                      </h4>{" "}
                    </div>
                    {/* .entry-header */}
                    <div className="entry-content">
                      <p>
                        Tri-tip bacon salami pork chop pork loin leberkas ham
                        hock venison pork belly prosciutto pancetta.
                        <br />
                        <a
                          href="https://webdesign-finder.com/comfort/cupim-bacon-short-ribs-picanha/#more-2768"
                          className="more-link"
                        >
                          More…
                        </a>
                      </p>
                      <div className="xs_social_share_widget xs_share_url after_content main_content wslu-style-1 wslu-share-box-shaped wslu-fill-colored wslu-none wslu-share-horizontal wslu-theme-font-no wslu-main_content">
                        <ul></ul>
                      </div>
                    </div>
                    {/* .entry-content */}
                  </div>
                  {/* eof .item-content */}
                </article>
                {/* #post-## */}
                {/* #post-## */}
                {/* #post-## */}
                {/* #post-## */}
                {/* #post-## */}
                {/* #post-## */}
                <nav className="loop-pagination text-center">
                  <ul className="pagination">
                    <li className="prev active disabled">
                      <span className="prev page-numbers">Prev</span>
                    </li>
                    <li className="active">
                      <span className="page-numbers current">1</span>
                    </li>
                    <li>
                      <a
                        className="page-numbers"
                        href="https://webdesign-finder.com/comfort/blog/page/2/"
                      >
                        2
                      </a>
                    </li>
                    <li className="next">
                      {" "}
                      <a
                        className="next page-numbers"
                        href="https://webdesign-finder.com/comfort/blog/page/2/"
                      >
                        Next
                      </a>{" "}
                    </li>
                  </ul>{" "}
                </nav>
                {/* .navigation */}
              </div>
              {/*eof #content */}
              {/* main aside sidebar */}
              <aside className="col-xs-12 col-sm-5 col-md-4 col-lg-4 col-sm-pull-7 col-md-pull-8 col-lg-pull-8">
                <div
                  id="mc4wp_form_widget-3"
                  className="widget-even widget-6 widget widget_mc4wp_form_widget"
                >
                  <h3 className="widget-title">Newsletter</h3>
                  <form
                    id="mc4wp-form-2"
                    className="mc4wp-form mc4wp-form-445"
                    method="post"
                    data-id={445}
                    data-name="Form"
                  >
                    <div className="mc4wp-form-fields">
                      <p className="mc4wp-form-inner">
                        <label>Email Address </label>
                        <input
                          className="form-control"
                          type="email"
                          name="EMAIL"
                          placeholder="Email address"
                          required=""
                        />
                        <button
                          type="submit"
                          className="theme_button color1"
                          value="Sign up"
                        />
                      </p>
                      <p>
                        Enter Email here to be updated. We promise not to send
                        you spam!
                      </p>
                    </div>
                  </form>
                  {/* / Mailchimp for WordPress Plugin */}
                </div>
                <div
                  id="comfort_theme_posts-3"
                  className="widget-odd widget-7 widget widget_popular_entries"
                >
                  {" "}
                  <h3 className="widget-title">
                    <span className="first-word">Recent</span>{" "}
                    <span className="last-word">Posts</span>
                  </h3>{" "}
                  <ul id="theme_posts_66029c8102c4b" className="media-list">
                    <li className="media post-2768 post type-post status-publish format-video has-post-thumbnail hentry category-uncategorized post_format-post-format-video">
                      <a
                        href="https://webdesign-finder.com/comfort/cupim-bacon-short-ribs-picanha/"
                        className="media-left"
                      >
                        <img
                          src="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-150x150.jpg"
                          className="attachment-thumbnail size-thumbnail wp-post-image"
                          alt=""
                          decoding="async"
                          loading="lazy"
                          srcSet="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-150x150.jpg 150w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-600x600.jpg 600w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-500x500.jpg 500w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-400x400.jpg 400w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/08-2-100x100.jpg 100w"
                          sizes="(max-width: 150px) 100vw, 150px"
                        />{" "}
                      </a>
                      <div className="media-body">
                        <h6 className="title">
                          <a href="https://webdesign-finder.com/comfort/cupim-bacon-short-ribs-picanha/">
                            Cupim bacon short ribs picanha
                          </a>
                        </h6>
                        <div className="item-meta">
                          <span className="widget-post-date highlight2">
                            August 11, 2017{" "}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="media post-2772 post type-post status-publish format-image has-post-thumbnail hentry category-sem post_format-post-format-image">
                      <a
                        href="https://webdesign-finder.com/comfort/swine-short-loin-boud-spare-ribs-capi/"
                        className="media-left"
                      >
                        <img
                          src="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/05-4-150x150.jpg"
                          className="attachment-thumbnail size-thumbnail wp-post-image"
                          alt=""
                          decoding="async"
                          loading="lazy"
                          srcSet="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/05-4-150x150.jpg 150w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/05-4-600x600.jpg 600w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/05-4-500x500.jpg 500w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/05-4-400x400.jpg 400w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/05-4-100x100.jpg 100w"
                          sizes="(max-width: 150px) 100vw, 150px"
                        />{" "}
                      </a>
                      <div className="media-body">
                        <h6 className="title">
                          <a href="https://webdesign-finder.com/comfort/swine-short-loin-boud-spare-ribs-capi/">
                            Swine short loin boud spare ribs capi
                          </a>
                        </h6>
                        <div className="item-meta">
                          <span className="widget-post-date highlight2">
                            August 7, 2017{" "}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="media post-2773 post type-post status-publish format-image has-post-thumbnail hentry category-sem post_format-post-format-image">
                      <a
                        href="https://webdesign-finder.com/comfort/ham-venison-spare-ribs-strip-steak/"
                        className="media-left"
                      >
                        <img
                          src="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/04-4-150x150.jpg"
                          className="attachment-thumbnail size-thumbnail wp-post-image"
                          alt=""
                          decoding="async"
                          loading="lazy"
                          srcSet="https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/04-4-150x150.jpg 150w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/04-4-600x600.jpg 600w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/04-4-500x500.jpg 500w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/04-4-400x400.jpg 400w, https://webdesign-finder.com/comfort/wp-content/uploads/2017/08/04-4-100x100.jpg 100w"
                          sizes="(max-width: 150px) 100vw, 150px"
                        />{" "}
                      </a>
                      <div className="media-body">
                        <h6 className="title">
                          <a href="https://webdesign-finder.com/comfort/ham-venison-spare-ribs-strip-steak/">
                            Ham venison spare ribs strip steak
                          </a>
                        </h6>
                        <div className="item-meta">
                          <span className="widget-post-date highlight2">
                            August 6, 2017{" "}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>{" "}
              </aside>
              {/* eof main aside sidebar */}
            </div>
            {/* eof .row*/}
          </div>
        </section>

        {/* <!-- ================== Footer  ================== --> */}

        {/* Footer start */}

        <Footer />

        {/* Footer end */}
      </div>
    </>
  );
}
export default BlogPage;
