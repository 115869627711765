import React from "react";
import TopHeader from "../components/commonComponents/TopHeader";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";

function AboutUsPage() {
  return (
    <>
      <div className="wrapper">
        {/* <!-- ========================  Header content ======================== --> */}

        {/* Top Header start */}
        <TopHeader />
        {/* Top Header end */}

        {/* logo & menu  start */}
        <Header />
        {/* logo & menu  end */}

        {/* ========================  About ======================== */}

        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cormorant sectionTitle text-white mt-4">
                  About Us
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section" data-aos="fade-up">
          {/*Header*/}
          {/* <header data-aos="fade-up"> */}
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text">
                  <p>
                    <span className="themeColor">At A+ RestorationPro </span>we
                    pride ourselves on delivering top-notch customer service for
                    both residential and commercial clients. We offer
                    full-service options or on-site repairs, whenever possible.
                    <br />
                    We specialize in complete furniture repair and restoration
                    of leather, fabric, wood, or antique furniture
                  </p>
                  {/* <p>
              <span class="themeColor">Our company </span>has earned the reputation for high quality, integrity and great customer service. Whether you have one piece
          or many, no job is too small or too large. Both commercial and residential clients can take advantage of our full
          service facility or have our repair technicians come to your office or home.</p> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="image box_info">
                  <img src="img/about.jpg" className="w-100" />
                </div>
              </div>
            </div>
          </div>
          {/* </header> */}
          {/*Main image*/}
          {/* <div class="container-fluid" data-aos="fade-up">
  <div class="row">
      <div class="col-md-12"> */}
          {/* </div>
  </div>
  
    </div> */}
        </section>
        <section className="bgImg section">
          <div className="container">
            <div className="row pt-5">
              <div className="col-md-2" />
              <div className="col-md-4 d-flex" data-aos="fade-left">
                <div className="box_info">
                  <h4 className="cormorant subTitle">Our Business</h4>
                  <p>
                    Founded in 2003, our family-owned and operated business has
                    been proudly serving Houston and its surrounding areas by
                    providing our customers with top-level furniture restoration
                    repairs and woodwork finishes.
                  </p>
                </div>
              </div>
              <div className="col-md-4 d-flex" data-aos="fade-up">
                <div className="box_info">
                  <h4 className="cormorant subTitle">Our Goal</h4>
                  <p>
                    <span className="themeColor">Our Goal</span> is to help you
                    restore your cherished antiques and various furniture pieces
                    with quality and care so you will enjoy it for a longer time
                    and create memories.
                  </p>
                </div>
              </div>
              <div className="col-md-2" />
              {/* <div class="col-md-4 d-flex" data-aos="fade-right"><div class="box_info">
     <h4 class="cormorant subTitle">MA+ RestorationPro Service's</h4>
          
          <p>
              <span class="themeColor">
                  A+ RestorationPro Service's </span>experienced technicians will handle all your repair needs from a simple repair
              to
              complete refinishing or reupholstering.
          
          
          </p>
          </div>
      </div> */}
            </div>
          </div>
        </section>
        <section className=" section">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-md-12">
                <h4 className="cormorant subTitle">
                  Some of our Commercial Account Customers
                </h4>
              </div>
              <div className="col-md-4">
                <ul className="listView">
                  <li>Allied Van Lines (Moving Company)</li>
                  <li> Willis Relocation Risk Group (Moving Company)</li>
                  <li> The Arrangement (Furniture Store)</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="listView">
                  <li> Blacklion (Furniture Store)</li>
                  <li>The Downtown Aquarium (Restaurant)</li>
                  <li> Castle Furniture (Furniture Store)</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="listView">
                  <li> Allstate (Insurance Company)</li>
                  <li> State Farm (Insurance Company)</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="blog blog-block">

    

      <header>
          <div class="container">
              <h2 class="title">Featured categories</h2>
              <div class="text">
                  <p>We just keep things minimal. <a href="category.html" class="btn btn-main">View more</a></p>
              </div>
          </div>
      </header>

    

      <div class="container">

          <div class="scroll-wrapper">

              <div class="row scroll text-center">

                

                  <div class="col-md-4">
                      <article data-3d>
                          <a href="products-grid.html">
                              <div class="image">
                                  <img src="img/product-1.jpg" alt="" />
                              </div>
                              <div class="entry entry-block">
                                  <div class="label">2020 Collection</div>
                                  <div class="title">
                                      <h2 class="h4">Beedroms</h2>
                                  </div>
                                  <div class="description d-none d-sm-block">
                                      <p>
                                          Top picks four your desire
                                      </p>
                                  </div>
                              </div>
                              <div class="show-more">
                                  <span class="btn btn-clean">Shop now</span>
                              </div>
                          </a>
                      </article>
                  </div>

                

                  <div class="col-md-4">
                      <article data-3d>
                          <a href="products-grid.html">
                              <div class="image">
                                  <img src="img/product-2.jpg" alt="" />
                              </div>
                              <div class="entry entry-block">
                                  <div class="label">Modern design</div>
                                  <div class="title">
                                      <h2 class="h4">Kitchens</h2>
                                  </div>
                                  <div class="description d-none d-sm-block">
                                      <p>
                                          Explore popular devices
                                      </p>
                                  </div>
                              </div>
                              <div class="show-more">
                                  <span class="btn btn-clean">Shop now</span>
                              </div>
                          </a>
                      </article>
                  </div>

                 

                  <div class="col-md-4">
                      <article data-3d>
                          <a href="products-grid.html">
                              <div class="image">
                                  <img src="img/product-3.jpg" alt="" />
                              </div>
                              <div class="entry entry-block">
                                  <div class="label">New discounts</div>
                                  <div class="title">
                                      <h2 class="h4">Living rooms</h2>
                                  </div>
                                  <div class="description d-none d-sm-block">
                                      <p>
                                          Available for quick shipping
                                      </p>
                                  </div>
                              </div>
                              <div class="show-more">
                                  <span class="btn btn-clean">Shop now</span>
                              </div>
                          </a>
                      </article>
                  </div>

              </div>
          </div>

      </div>

  </section> */}
        {/* ========================  Popular products  ======================== */}
        {/* <section class="products">

     

      <header>
          <div class="container">
              <h2 class="title">Popular products</h2>
              <div class="text">
                  <p>
                      Find your perfect match <a href="products-grid.html" class="btn btn-main">View all</a>
                  </p>
              </div>
          </div>
      </header>

    

      <div class="container">

          <div class="row">

            

              <div class="col-6 col-lg-4">
                  <article>
                      <div class="info">
                          <span class="add-favorite">
                              <a href="javascript:void(0);" data-title="Add to favorites"
                                  data-title-added="Added to favorites list">
                                  <i class="icon icon-heart"></i>
                              </a>
                          </span>
                          <span>
                              <a href="#productid1" class="mfp-open" data-title="Quick wiew">
                                  <i class="icon icon-eye"></i>
                              </a>
                          </span>
                      </div>
                      <div class="btn btn-add">
                          <i class="icon icon-cart"></i>
                      </div>
                      <div class="figure-grid">
                          <div class="image">
                              <a href="product.html">
                                  <img src="img/product-10.jpg" alt="" />
                              </a>
                          </div>
                          <div class="text">
                              <h2 class="title h4">
                                  <a href="product.html">Anna</a>
                              </h2>
                              <sub>$ 159,-</sub>
                              <sup>$ 139,-</sup>
                              <span class="description clearfix">
                                  Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                  lorem ea duo labore diam sit et consetetur nulla
                              </span>
                          </div>
                      </div>
                  </article>
              </div>

            

              <div class="col-6 col-lg-4">
                  <article>
                      <div class="info">
                          <span class="add-favorite">
                              <a href="javascript:void(0);" data-title="Add to favorites"
                                  data-title-added="Added to favorites list">
                                  <i class="icon icon-heart"></i>
                              </a>
                          </span>
                          <span>
                              <a href="#productid1" class="mfp-open" data-title="Quick wiew">
                                  <i class="icon icon-eye"></i>
                              </a>
                          </span>
                      </div>
                      <div class="btn btn-add">
                          <i class="icon icon-cart"></i>
                      </div>
                      <div class="figure-grid">
                          <span class="badge badge-warning">-20%</span>
                          <div class="image">
                              <a href="product.html">
                                  <img src="img/product-9.jpg" alt="" />
                              </a>
                          </div>
                          <div class="text">
                              <h2 class="title h4">
                                  <a href="product.html">Lucy</a>
                              </h2>
                              <sub>$ 319,-</sub>
                              <sup>$ 219,-</sup>
                              <span class="description clearfix">
                                  Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                  lorem ea duo labore diam sit et consetetur nulla
                              </span>
                          </div>
                      </div>
                  </article>
              </div>

           

              <div class="col-6 col-lg-4">
                  <article>
                      <div class="info">
                          <span class="add-favorite">
                              <a href="javascript:void(0);" data-title="Add to favorites"
                                  data-title-added="Added to favorites list">
                                  <i class="icon icon-heart"></i>
                              </a>
                          </span>
                          <span>
                              <a href="#productid1" class="mfp-open" data-title="Quick wiew">
                                  <i class="icon icon-eye"></i>
                              </a>
                          </span>
                      </div>
                      <div class="btn btn-add">
                          <i class="icon icon-cart"></i>
                      </div>
                      <div class="figure-grid">
                          <span class="badge badge-info">New arrival</span>
                          <div class="image">
                              <a href="product.html">
                                  <img src="img/product-8.jpg" alt="" />
                              </a>
                          </div>
                          <div class="text">
                              <h2 class="title h4">
                                  <a href="product.html">Ella</a>
                              </h2>
                              <sub>$ 899,-</sub>
                              <sup>$ 750,-</sup>
                              <span class="description clearfix">
                                  Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                  lorem ea duo labore diam sit et consetetur nulla
                              </span>
                          </div>
                      </div>
                  </article>
              </div>

             

              <div class="col-6 col-lg-4">
                  <article>
                      <div class="info">
                          <span class="add-favorite added">
                              <a href="javascript:void(0);" data-title="Add to favorites"
                                  data-title-added="Added to favorites list">
                                  <i class="icon icon-heart"></i>
                              </a>
                          </span>
                          <span>
                              <a href="#productid1" class="mfp-open" data-title="Quick wiew">
                                  <i class="icon icon-eye"></i>
                              </a>
                          </span>
                      </div>
                      <div class="btn btn-add">
                          <i class="icon icon-cart"></i>
                      </div>
                      <div class="figure-grid">
                          <div class="image">
                              <a href="product.html">
                                  <img src="img/product-7.jpg" alt="" />
                              </a>
                          </div>
                          <div class="text">
                              <h2 class="title h4">
                                  <a href="product.html">Grace</a>
                              </h2>
                              <sub>$ 699,-</sub>
                              <sup>$ 499,-</sup>
                              <span class="description clearfix">
                                  Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                  lorem ea duo labore diam sit et consetetur nulla
                              </span>
                          </div>
                      </div>
                  </article>
              </div>

             

              <div class="col-6 col-lg-4">
                  <article>
                      <div class="info">
                          <span class="add-favorite">
                              <a href="javascript:void(0);" data-title="Add to favorites"
                                  data-title-added="Added to favorites list">
                                  <i class="icon icon-heart"></i>
                              </a>
                          </span>
                          <span>
                              <a href="#productid1" class="mfp-open" data-title="Quick wiew">
                                  <i class="icon icon-eye"></i>
                              </a>
                          </span>
                      </div>
                      <div class="btn btn-add">
                          <i class="icon icon-cart"></i>
                      </div>
                      <div class="figure-grid">
                          <div class="image">
                              <a href="product.html">
                                  <img src="img/product-6.jpg" alt="" />
                              </a>
                          </div>
                          <div class="text">
                              <h2 class="title h4">
                                  <a href="product.html">Nora</a>
                              </h2>
                              <sub>$ 299,-</sub>
                              <sup>$ 199,-</sup>
                              <span class="description clearfix">
                                  Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                  lorem ea duo labore diam sit et consetetur nulla
                              </span>
                          </div>
                      </div>
                  </article>
              </div>

            

              <div class="col-6 col-lg-4">
                  <article>
                      <div class="info">
                          <span class="add-favorite">
                              <a href="javascript:void(0);" data-title="Add to favorites"
                                  data-title-added="Added to favorites list">
                                  <i class="icon icon-heart"></i>
                              </a>
                          </span>
                          <span>
                              <a href="#productid1" class="mfp-open" data-title="Quick wiew">
                                  <i class="icon icon-eye"></i>
                              </a>
                          </span>
                      </div>
                      <div class="btn btn-add">
                          <i class="icon icon-cart"></i>
                      </div>
                      <div class="figure-grid">
                          <div class="image">
                              <a href="product.html">
                                  <img src="img/product-5.jpg" alt="" />
                              </a>
                          </div>
                          <div class="text">
                              <h2 class="title h4">
                                  <a href="product.html">Sophie</a>
                              </h2>
                              <sub>$ 699,-</sub>
                              <sup>$ 499,-</sup>
                              <span class="description clearfix">
                                  Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                  lorem ea duo labore diam sit et consetetur nulla
                              </span>
                          </div>
                      </div>
                  </article>
              </div>

          </div> 

      </div>

  </section> */}
        {/* ========================  Product popup ======================== */}
        {/* <div class="popup-main mfp-hide" id="productid1">

    

      <div class="product">

       

          <div class="popup-title">
              <div class="h3 title">
                  Modern sofa
                  <small>product category</small>
              </div>
          </div>

       

          <div class="owl-product-gallery owl-theme owl-carousel">
              <img src="img/item-1.jpg" alt="" width="640" />
              <img src="img/item-2.jpg" alt="" width="640" />
          </div>

      

          <div class="popup-content">
              <div class="product-info-wrapper">
                  <div class="row">

                   

                      <div class="col-sm-6">
                          <div class="info-box">
                              <strong>Maifacturer</strong>
                              <span>Brand name</span>
                          </div>
                          <div class="info-box">
                              <strong>Materials</strong>
                              <span>Wood, Leather, Acrylic</span>
                          </div>
                          <div class="info-box">
                              <strong>Availability</strong>
                              <span><i class="fa fa-check-square-o"></i> in stock</span>
                          </div>
                      </div>

                 

                      <div class="col-sm-6">
                          <div class="info-box">
                              <strong>Available Colors</strong>
                              <div class="product-colors clearfix">
                                  <span class="color-btn color-btn-red"></span>
                                  <span class="color-btn color-btn-blue checked"></span>
                                  <span class="color-btn color-btn-green"></span>
                                  <span class="color-btn color-btn-gray"></span>
                                  <span class="color-btn color-btn-biege"></span>
                              </div>
                          </div>
                          <div class="info-box">
                              <strong>Choose size</strong>
                              <div class="product-colors clearfix">
                                  <span class="color-btn color-btn-biege">S</span>
                                  <span class="color-btn color-btn-biege checked">M</span>
                                  <span class="color-btn color-btn-biege">XL</span>
                                  <span class="color-btn color-btn-biege">XXL</span>
                              </div>
                          </div>
                      </div>

                  </div>
              </div> 
          </div> 

          <div class="popup-table">
              <div class="popup-cell">
                  <div class="price">
                      <span class="h3">$ 1999,00 <small>$ 2999,00</small></span>
                  </div>
              </div>
              <div class="popup-cell">
                  <div class="popup-buttons">
                      <a href="product.html"><span class="icon icon-eye"></span> <span class="hidden-xs">View
                              more</span></a>
                      <a href="javascript:void(0);"><span class="icon icon-cart"></span> <span
                              class="hidden-xs">Buy</span></a>
                  </div>
              </div>
          </div>

      </div> 
  </div> */}
        {/* ========================  Cards ======================== */}
        {/* <section class="cards">

   

      <header>
          <div class="container">
              <h2 class="title">Interior inspiration</h2>
              <div class="text">
                  <p>
                      Create an inviting space for yourself and family
                  </p>
              </div>
          </div>
      </header>

    

      <div class="container-fluid">

          <div class="row">

           

              <div class="col-lg-8">
                  <figure>
                      <figcaption style="background-image:url(img/box-1.jpg)">
                          <img src="img/box-1.jpg" alt="" />
                      </figcaption>
                      <a href="#interiorId1" class="btn btn-clean">Open up for easy living</a>
                  </figure>
              </div>

            

              <div class="col-lg-4">
                  <figure>
                      <figcaption style="background-image:url(img/box-2.jpg)">
                          <img src="img/box-2.jpg" alt="" />
                      </figcaption>
                      <a href="#interiorId1" class="btn btn-clean">Traditional looks</a>
                  </figure>
              </div>

            

              <div class="col-lg-4">
                  <figure>
                      <figcaption style="background-image:url(img/box-3.jpg)">
                          <img src="img/box-3.jpg" alt="" />
                      </figcaption>
                      <a href="#interiorId1" class="btn btn-clean">Sleek and sustainable</a>
                  </figure>
              </div>

           

              <div class="col-lg-4">
                  <figure>
                      <figcaption style="background-image:url(img/box-4.jpg)">
                          <img src="img/box-4.jpg" alt="" />
                      </figcaption>
                      <a href="#interiorId1" class="btn btn-clean">Compact living</a>
                  </figure>
              </div>

              

              <div class="col-lg-4">
                  <figure>
                      <figcaption style="background-image:url(img/box-5.jpg)">
                          <img src="img/box-5.jpg" alt="" />
                      </figcaption>
                      <a href="#interiorId1" class="btn btn-clean">Classic design</a>
                  </figure>
              </div>



          </div>

      </div> 

  </section> */}
        {/* ========================  Blog ======================== */}
        {/* <section class="blog blog-widget blog-animated">

     

      <header>
          <div class="container">
              <h2 class="h2 title">Design stories</h2>
              <div class="text">
                  <p>
                      Explore and unfold thoughts <a href="blog-grid.html" class="btn btn-main">View all</a>
                  </p>
              </div>
          </div>
      </header>

    

      <div class="container">

          <div class="row">

            

              <div class="col-md-12 col-lg-4">
                  <article>
                      <a href="article.html" class="blog-link">
                          <div class="image" style="background-image:url(img/product-1.jpg)">
                              <img src="img/product-1.jpg" alt="" />
                          </div>
                          <div class="entry entry-table">
                              <div class="date-wrapper">
                                  <div class="date">
                                      <span>Sep</span>
                                      <strong>08</strong>
                                      <span>2019</span>
                                  </div>
                              </div>
                              <div class="title">
                                  <h2 class="h5">Home Organizers that Add Color to Your Space</h2>
                              </div>
                          </div>
                      </a>
                  </article>
              </div>

            

              <div class="col-md-12 col-lg-4">
                  <article>
                      <a href="article.html" class="blog-link">
                          <div class="image" style="background-image:url(img/product-2.jpg)">
                              <img src="img/product-2.jpg" alt="" />
                          </div>
                          <div class="entry entry-table">
                              <div class="date-wrapper">
                                  <div class="date">
                                      <span>Sep</span>
                                      <strong>04</strong>
                                      <span>2019</span>
                                  </div>
                              </div>
                              <div class="title">
                                  <h2 class="h5">How to use room dividers in small spaces</h2>
                              </div>
                          </div>
                      </a>
                  </article>
              </div>

           

              <div class="col-md-12 col-lg-4">
                  <article>
                      <a href="article.html" class="blog-link">
                          <div class="image" style="background-image:url(img/product-3.jpg)">
                              <img src="img/product-3.jpg" alt="" />
                          </div>
                          <div class="entry entry-table">
                              <div class="date-wrapper">
                                  <div class="date">
                                      <span>Sep</span>
                                      <strong>01</strong>
                                      <span>2019</span>
                                  </div>
                              </div>
                              <div class="title">
                                  <h2 class="h5">How to pick a garden color palette</h2>
                              </div>
                          </div>
                      </a>
                  </article>
              </div>

          </div> 

      </div> 

  </section> */}
        {/* ========================  Newsletter ======================== */}
        {/* <section class="banner">

      <div class="container-fluid">

          <div class="banner-image" style="background-image:url(img/gallery-1.jpg)">
              

              <header>
                  <div class="container">

                      <h2 class="h2 title">Stay in touch!</h2>
                      <div class="text">
                          <p>Be first to know about all new interior features!</p>
                      </div>

                  </div>
              </header>

          

              <div class="container">
                  <div class="row align-items-center">
                      <div class="col-md-4">
                          <input type="email" class="form-control" name="name" value=""
                              placeholder="Enter your e-mail">
                      </div>
                      <div class="col-md-4">
                          <a href="#" class="btn btn-clean">Subscribe now</a>
                      </div>
                  </div>
              </div>
          </div>

      </div> 

  </section> */}
        {/* ========================  Instagram ======================== */}
        {/* ========================  Benefits ======================== */}

        {/* <!-- ================== Footer  ================== --> */}

        {/* Footer start */}

        <Footer />

        {/* Footer end */}
      </div>{" "}
      {/*/wrapper*/}
    </>
  );
}

export default AboutUsPage;
