// import React from "react";
import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
function Header() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      {/* logo & menu  start */}

      <div className="menuSection wowmenu">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-12">
              <nav className="navbar">
                <div className="nav-container">
                  <div className="nav-header">
                    <div className="nav-brand">
                      <a href="#">
                        <img
                          src="img/Restoration-pro-logo.png"
                          alt="Restoration Pro Logo"
                          className="mobLogo"
                          height="50"
                        />
                      </a>
                    </div>
                    <div className="nav-toggle"></div>
                  </div>

                  <div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          onClick={handleClick}
                          to="/"
                        >
                          {" "}
                          Home
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          onClick={handleClick}
                          to="/AboutUsPage"
                        >
                          {" "}
                          About Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          onClick={handleClick}
                          to="/OurServicesPage"
                        >
                          {" "}
                          Our Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          onClick={handleClick}
                          to="/PhotoGalleryPage"
                        >
                          {" "}
                          Photo Gallery
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          onClick={handleClick}
                          to="/BlogPage"
                        >
                          {" "}
                          Blog
                        </NavLink>
                      </li> */}

                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          onClick={handleClick}
                          to="/ContactUsPage"
                        >
                          {" "}
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                    <div className="nav-icon" onClick={handleClick}>
                      <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="menuSection wowmenu">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-12">
              <nav id="navigation1" className="navigation">
                <div className="nav-header">
                  <div className="nav-brand">
                    <a href="#">
                      <img
                        src="img/logo.png"
                        alt="img"
                        className="mobLogo"
                        height="50"
                      />
                    </a>
                  </div>
                  <div className="nav-toggle"></div>
                </div>

                <div className="nav-menus-wrapper">
                  <ul className="nav-menu">
                    <li>
                      <a href="#" className="nav-link active">
                        Home
                      </a>
                    </li>
                    <li>
                     

                      <Link className="nav-link" to="/AboutUsPage">
                        {" "}
                        About Us
                      </Link>
                    </li>
                    <li>
                     

                      <Link className="nav-link" to="/OurServicesPage">
                        {" "}
                        Our Services
                      </Link>
                    </li>
                    <li>
                     
                      <Link className="nav-link" to="/PhotoGalleryPage">
                        {" "}
                        Photo Gallery
                      </Link>
                    </li>
                    <li>
                   
                      <Link className="nav-link" to="/BlogPage">
                        {" "}
                        Blog
                      </Link>
                    </li>
                  
                    <li>
                    

                      <Link className="nav-link" to="/ContactUsPage">
                        {" "}
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      {/* logo & menu  end */}
    </>
  );
}

export default Header;
