import React from "react";
import {
  MDBCarousel,
  //   MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function Testim() {
  return (
    <MDBContainer className="my-5">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="section-title">
            <div className="cormorant sectionTitle">Our Testimonials</div>
          </div>
        </div>
      </div>
      <MDBCarousel showControls dark>
        {/* <MDBCarouselInner> */}
        <MDBCarouselItem className="active text-center">
          {/* <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
            alt="avatar"
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: "150px" }}
          /> */}
          {/* <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp"
            alt="avatar"
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: "150px" }}
          /> */}
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="12">
              {/* <h5 className="mb-3">Jeff</h5>
              <p>Jeffrey J. Brown</p> */}
              <p className="text-muted px-5">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                I’d like to put in a good word for Mr. Dan Hamdan. Mr.
                Hamdan repaired three pieces of furniture for us that were
                damaged in shipping during our move from St. Louis to Houston.
                One of the pieces was a one-of-a-kind mahogany lamp that was
                custom-made by my late cousin, a furniture maker from Colorado.
                Given the extensive damage to the lamp, its fragility, and its
                unique design, I did not think it would be possible to repair,
                but Mr. Hamdan proved me wrong. His craftsmanship and attention
                to detail were outstanding. He also did a great job repairing a
                desk and a buffet that were damaged in the move.
                <br />
                <br /> I would also like to thank Mr. Hamdan for his flexibility
                and kindness. My wife and I both work in healthcare and have
                unpredictable schedules. We were not the easiest customers to
                deal with, but Mr. Hamdan was invariably polite and kind despite
                the inconveniences we imposed upon him.
                <br />
                <br /> In summary, I could not have been more pleased with the
                service and outstanding work performed by Mr. Hamdan. He
                exceeded our expectations and I would not hesitate to use his
                services again or to recommend him to friends and relatives.
                <br />
                <br />
                Thank you!
              </p>
              <h5 className="mb-3">Jeff</h5>
              <p>Jeffrey J. Brown</p>
            </MDBCol>
          </MDBRow>
          {/* <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon far icon="star" size="sm" />
            </li>
          </ul> */}
        </MDBCarouselItem>

        {/* <MDBCarouselItem className="text-center">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp"
            alt="avatar"
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: "150px" }}
          />
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">John Doe</h5>
              <p>Web Developer</p>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                "Five-star service all around! From the initial consultation to
                the final delivery, this company demonstrated professionalism
                and expertise. They transformed my worn-out coffee table into a
                stunning centerpiece for my living room. I will be recommending
                them to all my friends and family!"
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon far icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem>

        <MDBCarouselItem className="text-center">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
            alt="avatar"
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: "150px" }}
          />
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">Anna Deynah</h5>
              <p>Web Developer</p>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                "I cannot express how impressed I am with the work done by this
                company. They exceeded all expectations in restoring my
                grandmother's dining table. Not only did they deliver
                outstanding results, but they also completed the job in a timely
                manner. Would definitely trust them with any future restoration
                projects!"
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon far icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem> */}
        {/* </MDBCarouselInner> */}
      </MDBCarousel>
    </MDBContainer>
  );
}
