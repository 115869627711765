import React from "react";
// import { BrowserRouter as Router, Routes, Link, Route } from "react-router-dom";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";

import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import PhotoGalleryPage from "./pages/PhotoGalleryPage";
import ContactUsPage from "./pages/ContactUsPage";
import OurServicesPage from "./pages/OurServicesPage";
import BlogPage from "./pages/BlogPage";
import FaqPage from "./pages/FaqPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AdminHome from "./pages/AdminHome";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/AboutUsPage" element={<AboutUsPage />} />
          <Route path="/PhotoGalleryPage" element={<PhotoGalleryPage />} />
          <Route path="/ContactUsPage" element={<ContactUsPage />} />
          <Route path="/OurServicesPage" element={<OurServicesPage />} />
          <Route path="/BlogPage" element={<BlogPage />} />
          <Route path="/FaqPage" element={<FaqPage />} />
          <Route path="/PrivacyPolicyPage" element={<PrivacyPolicyPage />} />
          <Route path="/admin" element={<AdminHome />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
