import { useState } from "react";
import axios from "axios";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
function RequestQuoteModel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedfile, SetSelectedFile] = useState([]);
  const [description, setDescription] = useState("");

  const InputChange = (e) => {
    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      // console.log(e.target.files[i]);
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        SetSelectedFile((preValue) => {
          // console.log(e.target.files[i].name);
          return [
            ...preValue,
            {
              id: shortid.generate(),
              filename: e.target.files[i].name,
              fileimage: reader.result,
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("description", description);
    selectedfile.forEach((file) => {
      formData.append("images[]", file.fileimage);
    });

    setIsSubmitting(true);
    axios
      .post("https://aplusrestorationpro.com/ajax/quote-request.php", formData)
      .then((response) => {
        if (response.data.result) {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            // timer: 1500
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        // Add any error handling here
      })
      .finally(() => {
        setIsSubmitting(false);
        setDescription("");
        SetSelectedFile([]);
      });
  };

  return (
    <>
      <div className="col-md-5 col-lg-6">
        <div className="quoteBtn">
          <button onClick={handleShow}>Request A Free Quote</button>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="modal-header">
          {/* <Modal.Title className="modal-title themeColor cormorant">Quotation Request Form</Modal.Title> */}
          <h5 className="modal-title themeColor cormorant">
            Quotation Request Form
          </h5>
          <button
            type="button"
            className="closeBtn"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <form id="quote_request_form">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1" className="mb-2">
                Description of the Job
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="upload__box">
              <div className="upload__btn-box">
                <label className="upload__btn">
                  <p>Upload images</p>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    className="upload__inputfile"
                    onChange={InputChange}
                  />
                </label>
              </div>
              <div className="upload__img-wrap">
                {selectedfile.map((data, index) => {
                  const { id, filename, fileimage } = data;

                  return (
                    <div className="upload__img-box" key={id}>
                      {filename.match(/.(jpg|jpeg|png)$/i) ? (
                        <div
                          className="img-bg"
                          style={{ backgroundImage: `url(${fileimage})` }}
                          data-number={id}
                          data-file={filename}
                        >
                          <div
                            className="upload__img-close"
                            onClick={() => DeleteSelectFile(id)}
                          ></div>
                        </div>
                      ) : (
                        <div className="file-image">
                          <i className="far fa-file-alt"></i>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <button type="button" className="themeBtn" onClick={handleSubmit}>
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RequestQuoteModel;
