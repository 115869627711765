import React from 'react'

function OurCustomers() {
  return (
    <>  {/* Our Customers start */}
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="cormorant sectionTitle"> Our Customers </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <div className="customerLogo">
                  <img src="img/guardsman-logo.jpg" alt="Guardsman Furniture Professionals Logo" style={{ width: '213', height: '117'  }}/>
                </div>
              </div>
              <div className="col-md-4">
                <div className="customerLogo">
                  <img src="img/room-and-board-logo.jpg" alt="Room and Board Logo"  style={{ width: '213', height: '117'  }} />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </section>
  {/* Our Customers end */}
    </>
  )
}

export default OurCustomers
