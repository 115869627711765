import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      {/* Footer start */}

      <div className="footer  py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <ul className="d-flex align-items-center mob-justify-content">
                <li>
                  {/* <a
                    target="blank"
                    href="faq.html"
                    title=""
                    className="text-white"
                  ></a> */}
                  <Link className="text-white" to="/FaqPage">
                    {" "}
                    FAQ
                  </Link>
                </li>
                <li className="px-2 text-white">|</li>
                <li>
                  {/* <a
                    target="blank"
                    href="privacypolicy.html"
                    className="text-white"
                  >
                    Privacy Policy
                  </a> */}
                  <Link className="text-white" to="/PrivacyPolicyPage">
                    {" "}
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 text-white text-center">
              Copyright 2024 | All rights reserved.
            </div>
            <div className="col-md-4">
              <ul className="socialIcon">
                <li>
                  <a
                    className=""
                    target="blank"
                    href="https://www.facebook.com"
                    title="Facebook"
                  >
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    target="blank"
                    href="https://www.instagram.com"
                    title="Instagram"
                  >
                    <i className="fa-brands fa-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    target="blank"
                    href="https://twitter.com"
                    title="Instagram"
                  >
                    <i className="fa-brands fa-linkedin-in" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div class="container-fluid">

          <div class="footer-wrap">

              <div class="container">


                  
                  <div class="footer-links">
                      <div class="row">
                          <div class="col-md-2">
                              <h5>Browse by</h5>
                              <ul>
                                  <li><a href="#">Brand</a></li>
                                  <li><a href="#">Product</a></li>
                                  <li><a href="#">Category</a></li>
                                  <li><a href="#">Projects</a></li>
                                  <li><a href="#">Sales</a></li>
                              </ul>
                          </div>
                          <div class="col-md-2">
                              <h5>Quick links</h5>
                              <ul>
                                  <li><a href="#">In-Store Pickup</a></li>
                                  <li><a href="#">Gift Cards</a></li>
                                  <li><a href="#">Afterpay</a></li>
                                  <li><a href="#">Shop</a></li>
                                  <li><a href="#">FAQ</a></li>
                              </ul>
                          </div>
                          <div class="col-md-2">
                              <h5>Order info</h5>
                              <ul>
                                  <li><a href="#">Order Status </a></li>
                                  <li><a href="#">Payments</a></li>
                                  <li><a href="#">Shipping</a></li>
                                  <li><a href="#">Returns</a></li>
                                  <li><a href="#">Order history</a></li>
                              </ul>
                          </div>
                          <div class="offset-md-3 col-md-3">
                              <h5>Sign up for our newsletter</h5>
                              <p><i>Add your email address to sign up for our monthly emails and to receive promotional offers.</i></p>
                              <div class="form-group form-newsletter">
                                  <input class="form-control" type="text" name="email" value="" placeholder="Email address">
                                  <input type="submit" class="btn btn-secondary btn-sm" value="Subscribe">
                              </div>
                          </div>
                      </div>
                  </div>

                

                  <div class="footer-social">
                      <div class="row">
                          <div class="col-sm-6">
                              <a href="#">Sitemap</a> &nbsp; | &nbsp; <a href="#">Privacy policy</a>
                          </div>
                          <div class="col-sm-6 links">
                              <ul>
                                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                  <li><a href="#"><i class="fa fa-youtube"></i></a></li>
                                  <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div> */}
      </div>

      {/* Footer end */}
    </>
  );
}

export default Footer;
