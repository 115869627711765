import React from "react";
import RequestQuoteModel from "./RequestQuoteModel";

function TopHeader() {
  return (
    <>
      {/* Top Header start */}
      <div className="topHeader">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-6 mobCenter">
              <a href="mailto:guardsmanpro@yahoo.com" className="text-white">
                <i className="fa-light fa-envelope pe-1"></i>{" "}
                guardsmanpro@yahoo.com
              </a>
              <span> | </span>
              <a href="tel:+281-633-0212" className="text-white">
                <i className="fa-light fa-phone pe-1"></i> 281-633-0212
              </a>
            </div>
            {/* <div className="col-md-6 col-lg-8">
                <div className="quoteBtn">
                  <a href="#" data-toggle="modal" data-target="#exampleModal">
                    Request Quote
                  </a>
                 
                </div>
              </div> */}
            <RequestQuoteModel />
          </div>
        </div>
      </div>

      {/* Top Header end */}
    </>
  );
}

export default TopHeader;
