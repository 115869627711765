import React from 'react'

function AdminHome() {
  return (
    <>
      ddfvfds
    </>
  )
}

export default AdminHome
