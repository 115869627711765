import React from "react";

import TopHeader from "../components/commonComponents/TopHeader";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";

// import TestimonialsSection from "../components/homeComponents/TestimonialsSection";

import OurCustomers from "../components/homeComponents/OurCustomers";
import BannerSlider from "../components/homeComponents/BannerSlider";
import Testim from "../components/homeComponents/Testim";
import RequestQuoteModel from "../components/homeComponents/RequestQuoteModel";

function HomePage() {
  return (
    <>
      {/* wrapper end */}
      <div className="wrapper">
        {/* <!-- ========================  Header content ======================== --> */}

        {/* Top Header start */}
        <TopHeader />
        {/* Top Header end */}

        {/* logo & menu  start */}
        <Header />
        {/* logo & menu  end */}

        {/* home slider start */}
        <BannerSlider />
        {/* home slider end */}

        {/* <!-- ========================  Featured categories ======================== --> */}

        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-6" data-aos="fade-right">
                <div
                  className="position-relative overflow-hidden ps-5 pt-5 h-100"
                  //   style="min-height: 400px;"
                  style={{ minHeight: "400px" }}
                >
                  <img
                    className="position-absolute w-100 h-100"
                    src="img/fimage.jpg"
                    alt="img"
                    style={{ objectFit: "cover" }}
                  />
                  <div
                    className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                    // style="width: 200px; height: 200px;"
                    style={{ width: "200px", height: "200px" }}
                  >
                    <div className="d-flex flex-column justify-content-center text-center themeBg h-100 p-3">
                      <h1 className="text-white fs-3">
                        <span>Over</span> 21
                      </h1>
                      <h2 className="text-white mt-0 mb-2 fs-3">Years</h2>
                      <h2 className="text-white my-0 fs-3">Experience</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-left">
                <div className="cormorant sectionTitle">
                  Welcome to A+ RestorationPro.
                </div>
                <p>
                  Founded in 2003, our family-owned and operated business has
                  been proudly serving Houston and its surrounding areas by
                  providing our customers with top-level furniture restoration
                  repairs and woodwork finishes.
                </p>
                {/* <div class="cormorant sectionTitle">Welcome to our web page.</div> */}
                <p>
                  <span className="themeColor">Our goal</span> Our goal is to
                  help you restore your cherished antiques and various furniture
                  pieces with quality and care so you will enjoy it for a longer
                  time and create memories.
                </p>
                {/* <p>
          <span class="themeColor">Our goal</span> on this site is to provide you with information that will help you make informed and confident decisions about
          your prized furniture items or woodwork. <br>
          We also hope you will feel confident in our ability to satisfy the repair,
          restoration, refinishing, cleaning, and upholstery of your furniture, whether antique or more recent.
      </p> */}
                <p>
                  <span className="themeColor">Our mission</span> is to provide
                  professional, courteous service and quality workmanship to
                  every customer.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bgImg section">
          <div className="container">
            <div className="row">
              <div className="col-md-7" data-aos="fade-left">
                <div className="cormorant sectionTitle">How We Do It</div>
                <p>
                  Our experts travel to your location regardless of the size of
                  the furniture- no job is too small.
                </p>
                <p>
                  While we offer a wide range of services, repairs can be
                  challenging to estimate without seeing the item firsthand.
                  Therefore, we recommend emailing us photographs of the repair
                  needed, and we can discuss the details over the phone. You'll
                  receive a free quote within 24 hours.
                </p>
                {/* <p>
  We provide complete furniture repair and restoration to your leather, fabric, wood or antique furniture. Our specialists
  travel to your location for minor or major repair or touch - up of furniture or wood work. No job is too small.
    </p>
    <p>
  Of the many services we offer, repairs are typically the most difficult to estimate without actually seeing the item in
  front of us. Therefore, if you have a repair you would like information about before bringing it in to our shop we
  suggest e-mailing photographs for us to look at while we talk together about the repair needs over the phone (a free
  quote would be provided within 24 hours).
    </p> */}
                {/* <ul class="listView">
  <li class="">Award-winning service</li>
  <li>Satisfaction guarantee</li>
    </ul> */}
                {/* <div class="col-md-6 col-lg-8"> */}
                {/* <div class="quoteBtn"> */}

                <RequestQuoteModel />

                {/* </div> */}
                {/* </div> */}
              </div>
              <div className="col-md-5" data-aos="fade-right">
                <img src="img/fimage2.webp" className="img-fluid mobMt" alt="A person inspecting wood to create Furniture" style={{ width: '276', height: '329'  }} />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="section-title">
                  <div className="cormorant sectionTitle">Our Testimonials</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="testimonial-slider" className="owl-carousel">
                  <div className="testimonial">
                    <div className="description">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vivamus ipsum augue, lacinia vel arcu condimentum,
                        dictum tristique sem. Nam enim neque, mattis ut
                        fermentum vitae, venenatis id tortor. Sed consectetur
                        tortor ut arcu pharetra faucibus. In tristique
                        sollicitudin mi ac congue. Praesent efficitur feugiat
                        nisl, nec facilisis leo sollicitudin sed. Integer
                        egestas porttitor massa, ut dapibus sapien feugiat eget.
                        Nam.
                      </p>
                    </div>
                    <div className="testimonial-review">
                      <img src="img/img-1.jpg" alt="" />
                      <h3 className="testimonial-title">
                        williamson
                        <small>abc company</small>
                      </h3>
                      <ul className="rating">
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star-half-o"></i>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="testimonial">
                    <div className="description">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vivamus ipsum augue, lacinia vel arcu condimentum,
                        dictum tristique sem. Nam enim neque, mattis ut
                        fermentum vitae, venenatis id tortor. Sed consectetur
                        tortor ut arcu pharetra faucibus. In tristique
                        sollicitudin mi ac congue. Praesent efficitur feugiat
                        nisl, nec facilisis leo sollicitudin sed. Integer
                        egestas porttitor massa, ut dapibus sapien feugiat eget.
                        Nam.
                      </p>
                    </div>
                    <div className="testimonial-review">
                      <img src="img/img-1.jpg" alt="" />
                      <h3 className="testimonial-title">
                        williamson
                        <small>xyz company</small>
                      </h3>
                      <ul className="rating">
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star-half-o"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonial">
                    <div className="description">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vivamus ipsum augue, lacinia vel arcu condimentum,
                        dictum tristique sem. Nam enim neque, mattis ut
                        fermentum vitae, venenatis id tortor. Sed consectetur
                        tortor ut arcu pharetra faucibus. In tristique
                        sollicitudin mi ac congue. Praesent efficitur feugiat
                        nisl, nec facilisis leo sollicitudin sed. Integer
                        egestas porttitor massa, ut dapibus sapien feugiat eget.
                        Nam.
                      </p>
                    </div>
                    <div className="testimonial-review">
                      <img src="img/img-3.jpg" alt="" />
                      <h3 className="testimonial-title">
                        kristiana
                        <small>def Company</small>
                      </h3>
                      <ul className="rating">
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star-half-o"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Testimonials Section  start*/}

        {/* <TestimonialsSection /> */}

        <Testim />

        {/* Testimonials Section  end*/}

        {/* Our Customers start */}
        <OurCustomers />
        {/* Our Customers end */}

        {/* <!-- <section className="blog blog-block">

  

    <header>
        <div className="container">
            <h2 className="title">Featured categories</h2>
            <div className="text">
                <p>We just keep things minimal. <a href="category.html" className="btn btn-main">View more</a></p>
            </div>
        </div>
    </header>

  

    <div className="container">

        <div className="scroll-wrapper">

            <div className="row scroll text-center">

              

                <div className="col-md-4">
                    <article data-3d>
                        <a href="products-grid.html">
                            <div className="image">
                                <img src="img/product-1.jpg" alt="" />
                            </div>
                            <div className="entry entry-block">
                                <div className="label">2020 Collection</div>
                                <div className="title">
                                    <h2 className="h4">Beedroms</h2>
                                </div>
                                <div className="description d-none d-sm-block">
                                    <p>
                                        Top picks four your desire
                                    </p>
                                </div>
                            </div>
                            <div className="show-more">
                                <span className="btn btn-clean">Shop now</span>
                            </div>
                        </a>
                    </article>
                </div>

              

                <div className="col-md-4">
                    <article data-3d>
                        <a href="products-grid.html">
                            <div className="image">
                                <img src="img/product-2.jpg" alt="" />
                            </div>
                            <div className="entry entry-block">
                                <div className="label">Modern design</div>
                                <div className="title">
                                    <h2 className="h4">Kitchens</h2>
                                </div>
                                <div className="description d-none d-sm-block">
                                    <p>
                                        Explore popular devices
                                    </p>
                                </div>
                            </div>
                            <div className="show-more">
                                <span className="btn btn-clean">Shop now</span>
                            </div>
                        </a>
                    </article>
                </div>

               

                <div className="col-md-4">
                    <article data-3d>
                        <a href="products-grid.html">
                            <div className="image">
                                <img src="img/product-3.jpg" alt="" />
                            </div>
                            <div className="entry entry-block">
                                <div className="label">New discounts</div>
                                <div className="title">
                                    <h2 className="h4">Living rooms</h2>
                                </div>
                                <div className="description d-none d-sm-block">
                                    <p>
                                        Available for quick shipping
                                    </p>
                                </div>
                            </div>
                            <div className="show-more">
                                <span className="btn btn-clean">Shop now</span>
                            </div>
                        </a>
                    </article>
                </div>

            </div>
        </div>

    </div>

</section> --> */}

        {/* <!-- ========================  Popular products  ======================== --> */}

        {/* <!-- <section className="products">

   

    <header>
        <div className="container">
            <h2 className="title">Popular products</h2>
            <div className="text">
                <p>
                    Find your perfect match <a href="products-grid.html" className="btn btn-main">View all</a>
                </p>
            </div>
        </div>
    </header>

  

    <div className="container">

        <div className="row">

          

            <div className="col-6 col-lg-4">
                <article>
                    <div className="info">
                        <span className="add-favorite">
                            <a href="javascript:void(0);" data-title="Add to favorites"
                                data-title-added="Added to favorites list">
                                <i className="icon icon-heart"></i>
                            </a>
                        </span>
                        <span>
                            <a href="#productid1" className="mfp-open" data-title="Quick wiew">
                                <i className="icon icon-eye"></i>
                            </a>
                        </span>
                    </div>
                    <div className="btn btn-add">
                        <i className="icon icon-cart"></i>
                    </div>
                    <div className="figure-grid">
                        <div className="image">
                            <a href="product.html">
                                <img src="img/product-10.jpg" alt="" />
                            </a>
                        </div>
                        <div className="text">
                            <h2 className="title h4">
                                <a href="product.html">Anna</a>
                            </h2>
                            <sub>$ 159,-</sub>
                            <sup>$ 139,-</sup>
                            <span className="description clearfix">
                                Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                lorem ea duo labore diam sit et consetetur nulla
                            </span>
                        </div>
                    </div>
                </article>
            </div>

          

            <div className="col-6 col-lg-4">
                <article>
                    <div className="info">
                        <span className="add-favorite">
                            <a href="javascript:void(0);" data-title="Add to favorites"
                                data-title-added="Added to favorites list">
                                <i className="icon icon-heart"></i>
                            </a>
                        </span>
                        <span>
                            <a href="#productid1" className="mfp-open" data-title="Quick wiew">
                                <i className="icon icon-eye"></i>
                            </a>
                        </span>
                    </div>
                    <div className="btn btn-add">
                        <i className="icon icon-cart"></i>
                    </div>
                    <div className="figure-grid">
                        <span className="badge badge-warning">-20%</span>
                        <div className="image">
                            <a href="product.html">
                                <img src="img/product-9.jpg" alt="" />
                            </a>
                        </div>
                        <div className="text">
                            <h2 className="title h4">
                                <a href="product.html">Lucy</a>
                            </h2>
                            <sub>$ 319,-</sub>
                            <sup>$ 219,-</sup>
                            <span className="description clearfix">
                                Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                lorem ea duo labore diam sit et consetetur nulla
                            </span>
                        </div>
                    </div>
                </article>
            </div>

         

            <div className="col-6 col-lg-4">
                <article>
                    <div className="info">
                        <span className="add-favorite">
                            <a href="javascript:void(0);" data-title="Add to favorites"
                                data-title-added="Added to favorites list">
                                <i className="icon icon-heart"></i>
                            </a>
                        </span>
                        <span>
                            <a href="#productid1" className="mfp-open" data-title="Quick wiew">
                                <i className="icon icon-eye"></i>
                            </a>
                        </span>
                    </div>
                    <div className="btn btn-add">
                        <i className="icon icon-cart"></i>
                    </div>
                    <div className="figure-grid">
                        <span className="badge badge-info">New arrival</span>
                        <div className="image">
                            <a href="product.html">
                                <img src="img/product-8.jpg" alt="" />
                            </a>
                        </div>
                        <div className="text">
                            <h2 className="title h4">
                                <a href="product.html">Ella</a>
                            </h2>
                            <sub>$ 899,-</sub>
                            <sup>$ 750,-</sup>
                            <span className="description clearfix">
                                Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                lorem ea duo labore diam sit et consetetur nulla
                            </span>
                        </div>
                    </div>
                </article>
            </div>

           

            <div className="col-6 col-lg-4">
                <article>
                    <div className="info">
                        <span className="add-favorite added">
                            <a href="javascript:void(0);" data-title="Add to favorites"
                                data-title-added="Added to favorites list">
                                <i className="icon icon-heart"></i>
                            </a>
                        </span>
                        <span>
                            <a href="#productid1" className="mfp-open" data-title="Quick wiew">
                                <i className="icon icon-eye"></i>
                            </a>
                        </span>
                    </div>
                    <div className="btn btn-add">
                        <i className="icon icon-cart"></i>
                    </div>
                    <div className="figure-grid">
                        <div className="image">
                            <a href="product.html">
                                <img src="img/product-7.jpg" alt="" />
                            </a>
                        </div>
                        <div className="text">
                            <h2 className="title h4">
                                <a href="product.html">Grace</a>
                            </h2>
                            <sub>$ 699,-</sub>
                            <sup>$ 499,-</sup>
                            <span className="description clearfix">
                                Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                lorem ea duo labore diam sit et consetetur nulla
                            </span>
                        </div>
                    </div>
                </article>
            </div>

           

            <div className="col-6 col-lg-4">
                <article>
                    <div className="info">
                        <span className="add-favorite">
                            <a href="javascript:void(0);" data-title="Add to favorites"
                                data-title-added="Added to favorites list">
                                <i className="icon icon-heart"></i>
                            </a>
                        </span>
                        <span>
                            <a href="#productid1" className="mfp-open" data-title="Quick wiew">
                                <i className="icon icon-eye"></i>
                            </a>
                        </span>
                    </div>
                    <div className="btn btn-add">
                        <i className="icon icon-cart"></i>
                    </div>
                    <div className="figure-grid">
                        <div className="image">
                            <a href="product.html">
                                <img src="img/product-6.jpg" alt="" />
                            </a>
                        </div>
                        <div className="text">
                            <h2 className="title h4">
                                <a href="product.html">Nora</a>
                            </h2>
                            <sub>$ 299,-</sub>
                            <sup>$ 199,-</sup>
                            <span className="description clearfix">
                                Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                lorem ea duo labore diam sit et consetetur nulla
                            </span>
                        </div>
                    </div>
                </article>
            </div>

          

            <div className="col-6 col-lg-4">
                <article>
                    <div className="info">
                        <span className="add-favorite">
                            <a href="javascript:void(0);" data-title="Add to favorites"
                                data-title-added="Added to favorites list">
                                <i className="icon icon-heart"></i>
                            </a>
                        </span>
                        <span>
                            <a href="#productid1" className="mfp-open" data-title="Quick wiew">
                                <i className="icon icon-eye"></i>
                            </a>
                        </span>
                    </div>
                    <div className="btn btn-add">
                        <i className="icon icon-cart"></i>
                    </div>
                    <div className="figure-grid">
                        <div className="image">
                            <a href="product.html">
                                <img src="img/product-5.jpg" alt="" />
                            </a>
                        </div>
                        <div className="text">
                            <h2 className="title h4">
                                <a href="product.html">Sophie</a>
                            </h2>
                            <sub>$ 699,-</sub>
                            <sup>$ 499,-</sup>
                            <span className="description clearfix">
                                Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam
                                lorem ea duo labore diam sit et consetetur nulla
                            </span>
                        </div>
                    </div>
                </article>
            </div>

        </div> 

    </div>

</section> --> */}

        {/* <!-- ========================  Product popup ======================== --> */}

        {/* <!-- <div className="popup-main mfp-hide" id="productid1">

  

    <div className="product">

     

        <div className="popup-title">
            <div className="h3 title">
                Modern sofa
                <small>product category</small>
            </div>
        </div>

     

        <div className="owl-product-gallery owl-theme owl-carousel">
            <img src="img/item-1.jpg" alt="" width="640" />
            <img src="img/item-2.jpg" alt="" width="640" />
        </div>

    

        <div className="popup-content">
            <div className="product-info-wrapper">
                <div className="row">

                 

                    <div className="col-sm-6">
                        <div className="info-box">
                            <strong>Maifacturer</strong>
                            <span>Brand name</span>
                        </div>
                        <div className="info-box">
                            <strong>Materials</strong>
                            <span>Wood, Leather, Acrylic</span>
                        </div>
                        <div className="info-box">
                            <strong>Availability</strong>
                            <span><i className="fa fa-check-square-o"></i> in stock</span>
                        </div>
                    </div>

               

                    <div className="col-sm-6">
                        <div className="info-box">
                            <strong>Available Colors</strong>
                            <div className="product-colors clearfix">
                                <span className="color-btn color-btn-red"></span>
                                <span className="color-btn color-btn-blue checked"></span>
                                <span className="color-btn color-btn-green"></span>
                                <span className="color-btn color-btn-gray"></span>
                                <span className="color-btn color-btn-biege"></span>
                            </div>
                        </div>
                        <div className="info-box">
                            <strong>Choose size</strong>
                            <div className="product-colors clearfix">
                                <span className="color-btn color-btn-biege">S</span>
                                <span className="color-btn color-btn-biege checked">M</span>
                                <span className="color-btn color-btn-biege">XL</span>
                                <span className="color-btn color-btn-biege">XXL</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div> 
        </div> 

        <div className="popup-table">
            <div className="popup-cell">
                <div className="price">
                    <span className="h3">$ 1999,00 <small>$ 2999,00</small></span>
                </div>
            </div>
            <div className="popup-cell">
                <div className="popup-buttons">
                    <a href="product.html"><span className="icon icon-eye"></span> <span className="hidden-xs">View
                            more</span></a>
                    <a href="javascript:void(0);"><span className="icon icon-cart"></span> <span
                            className="hidden-xs">Buy</span></a>
                </div>
            </div>
        </div>

    </div> 
</div> --> */}

        {/* <!-- ========================  Cards ======================== --> */}

        {/* <!-- <section className="cards">

 

    <header>
        <div className="container">
            <h2 className="title">Interior inspiration</h2>
            <div className="text">
                <p>
                    Create an inviting space for yourself and family
                </p>
            </div>
        </div>
    </header>

  

    <div className="container-fluid">

        <div className="row">

         

            <div className="col-lg-8">
                <figure>
                    <figcaption style="background-image:url(img/box-1.jpg)">
                        <img src="img/box-1.jpg" alt="" />
                    </figcaption>
                    <a href="#interiorId1" className="btn btn-clean">Open up for easy living</a>
                </figure>
            </div>

          

            <div className="col-lg-4">
                <figure>
                    <figcaption style="background-image:url(img/box-2.jpg)">
                        <img src="img/box-2.jpg" alt="" />
                    </figcaption>
                    <a href="#interiorId1" className="btn btn-clean">Traditional looks</a>
                </figure>
            </div>

          

            <div className="col-lg-4">
                <figure>
                    <figcaption style="background-image:url(img/box-3.jpg)">
                        <img src="img/box-3.jpg" alt="" />
                    </figcaption>
                    <a href="#interiorId1" className="btn btn-clean">Sleek and sustainable</a>
                </figure>
            </div>

         

            <div className="col-lg-4">
                <figure>
                    <figcaption style="background-image:url(img/box-4.jpg)">
                        <img src="img/box-4.jpg" alt="" />
                    </figcaption>
                    <a href="#interiorId1" className="btn btn-clean">Compact living</a>
                </figure>
            </div>

            

            <div className="col-lg-4">
                <figure>
                    <figcaption style="background-image:url(img/box-5.jpg)">
                        <img src="img/box-5.jpg" alt="" />
                    </figcaption>
                    <a href="#interiorId1" className="btn btn-clean">Classic design</a>
                </figure>
            </div>



        </div>

    </div> 

</section> --> */}

        {/* <!-- ========================  Blog ======================== --> */}

        {/* <!-- <section className="blog blog-widget blog-animated">

   

    <header>
        <div className="container">
            <h2 className="h2 title">Design stories</h2>
            <div className="text">
                <p>
                    Explore and unfold thoughts <a href="blog-grid.html" className="btn btn-main">View all</a>
                </p>
            </div>
        </div>
    </header>

  

    <div className="container">

        <div className="row">

          

            <div className="col-md-12 col-lg-4">
                <article>
                    <a href="article.html" className="blog-link">
                        <div className="image" style="background-image:url(img/product-1.jpg)">
                            <img src="img/product-1.jpg" alt="" />
                        </div>
                        <div className="entry entry-table">
                            <div className="date-wrapper">
                                <div className="date">
                                    <span>Sep</span>
                                    <strong>08</strong>
                                    <span>2019</span>
                                </div>
                            </div>
                            <div className="title">
                                <h2 className="h5">Home Organizers that Add Color to Your Space</h2>
                            </div>
                        </div>
                    </a>
                </article>
            </div>

          

            <div className="col-md-12 col-lg-4">
                <article>
                    <a href="article.html" className="blog-link">
                        <div className="image" style="background-image:url(img/product-2.jpg)">
                            <img src="img/product-2.jpg" alt="" />
                        </div>
                        <div className="entry entry-table">
                            <div className="date-wrapper">
                                <div className="date">
                                    <span>Sep</span>
                                    <strong>04</strong>
                                    <span>2019</span>
                                </div>
                            </div>
                            <div className="title">
                                <h2 className="h5">How to use room dividers in small spaces</h2>
                            </div>
                        </div>
                    </a>
                </article>
            </div>

         

            <div className="col-md-12 col-lg-4">
                <article>
                    <a href="article.html" className="blog-link">
                        <div className="image" style="background-image:url(img/product-3.jpg)">
                            <img src="img/product-3.jpg" alt="" />
                        </div>
                        <div className="entry entry-table">
                            <div className="date-wrapper">
                                <div className="date">
                                    <span>Sep</span>
                                    <strong>01</strong>
                                    <span>2019</span>
                                </div>
                            </div>
                            <div className="title">
                                <h2 className="h5">How to pick a garden color palette</h2>
                            </div>
                        </div>
                    </a>
                </article>
            </div>

        </div> 

    </div> 

</section> --> */}

        {/* <!-- ========================  Newsletter ======================== --> */}

        {/* <!-- <section className="banner">

    <div className="container-fluid">

        <div className="banner-image" style="background-image:url(img/gallery-1.jpg)">
            

            <header>
                <div className="container">

                    <h2 className="h2 title">Stay in touch!</h2>
                    <div className="text">
                        <p>Be first to know about all new interior features!</p>
                    </div>

                </div>
            </header>

        

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <input type="email" className="form-control" name="name" value=""
                            placeholder="Enter your e-mail">
                    </div>
                    <div className="col-md-4">
                        <a href="#" className="btn btn-clean">Subscribe now</a>
                    </div>
                </div>
            </div>
        </div>

    </div> 

</section> --> */}

        {/* <!-- ================== Footer  ================== --> */}

        {/* Footer start */}

        <Footer />

        {/* Footer end */}
      </div>
      {/* wrapper end */}
    </>
  );
}

export default HomePage;
