import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
function ContactForm() {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    if (firstName.length === 0) {
      alert("First name has left Blank!");
    } else if (email.length === 0) {
      alert("Email has left Blank!");
    } else if (mobileNumber.length === 0) {
      alert("Mobile number has left Blank!");
    } else if (subject.length === 0) {
      alert("Subject has left Blank!");
    } else if (comments.length === 0) {
      alert("Comment has left Blank!");
    } else {
      const url = "https://aplusrestorationpro.com/ajax/contact-form.php";

      let fData = new FormData();
      fData.append("firstName", firstName);
      fData.append("email", email);
      fData.append("lastName", lastName);
      fData.append("mobileNumber", mobileNumber);
      fData.append("subject", subject);
      fData.append("comments", comments);
      setIsSubmitting(true);

      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.result) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              // timer: 1500
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("An error occurred while inserting contact. " + error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setSubject("");
          setComments("");
        });
    }
  };

  return (
    <>
      <div className="contact-info">
        <h2 className="title">Send an email</h2>
        <p>
          Thanks for your interest in A+ RestorationPro Inc. Please use this
          form if you have any questions about our services and we'll get back
          with you very soon.
        </p>
        <div className="contact-form-wrapper">
          <div className="contact-form clearfix">
            <form action="#" method="POST">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control"
                      placeholder="First name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control"
                      placeholder="Last name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Your email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="mobileNumber"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className="form-control"
                      placeholder="Mobile Number"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="form-control"
                      placeholder="Subject"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      name="comments"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      className="form-control"
                      placeholder="Comments"
                      rows={10}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="themeBtn"
                    onClick={handleSubmit}
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
