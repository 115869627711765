import React from "react";

import TopHeader from "../components/commonComponents/TopHeader";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";
function FaqPage() {
  return (
    <>
      <div className="wrapper">
        {/* <!-- ========================  Header content ======================== --> */}

        {/* Top Header start */}
        <TopHeader />
        {/* Top Header end */}

        {/* logo & menu  start */}
        <Header />
        {/* logo & menu  end */}

        {/* ========================  FAQ  ======================== */}

        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cormorant sectionTitle text-white mt-4">
                  FAQ
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section">
          {/*Header*/}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="accordion" className="customAccordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {/*        <span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="far fa-plus-square"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="far fa-minus-square"></i></span>
														</span> */}
                          Can I show more items than service was requested for?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        Yes, you can show as many items or services you may need
                        and technicians will gladly assist. However if service
                        requested/paid by a third party tech may have to go by
                        the work order and/or call the appropriate party for an
                        approval.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {/*  <span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="far fa-plus-square"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="far fa-minus-square"></i></span>
														</span>  */}
                          Why do technicians take pictures of my furniture, tags
                          and damages?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        It is important for us to have a reference of the items
                        and issues in order to assist you, customer and the
                        company we represent in the best possible way.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {/*   <span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="far fa-plus-square"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="far fa-minus-square"></i></span>
														</span>   */}
                          When can I expect to hear from you, and who will be
                          coming into my home to service my furniture?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        We call, email, and text customers within the same day
                        or next day if the service request received after
                        business hours. We follow up every other day with any
                        unscheduled customers. The best/most qualified
                        technician for the service being requested will be sent
                        depending on the customer and technicians availability.
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          {/*        <span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="far fa-plus-square"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="far fa-minus-square"></i></span>
														</span> */}
                          Will the technician have any identifying paperwork?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        Yes, technicians will have paperwork related to your
                        service along with their ID/Driver License and company
                        business cards.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapseFive"
                        >
                          {/*        <span class="elementor-accordion-icon elementor-accordion-icon-left" aria-hidden="true">
															<span class="elementor-accordion-icon-closed"><i class="far fa-plus-square"></i></span>
								<span class="elementor-accordion-icon-opened"><i class="far fa-minus-square"></i></span>
														</span> */}
                          Will the technician remove his/her shoes or use shoe
                          covers?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        Our technicians may carry shoe covers/booties and can
                        wear those or take off their shoes if requested to do
                        so.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ========================  FAQ ======================== */}

        {/* ========================  Instagram ======================== */}
        {/* ========================  Benefits ======================== */}
        {/* <!-- ================== Footer  ================== --> */}

        {/* Footer start */}

        <Footer />

        {/* Footer end */}
      </div>
    </>
  );
}

export default FaqPage;
