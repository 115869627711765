import React from "react";

import TopHeader from "../components/commonComponents/TopHeader";
import Header from "../components/commonComponents/Header";
import Footer from "../components/commonComponents/Footer";

function PhotoGalleryPage() {
  return (
    <>
      <div className="wrapper">
        {/* <!-- ========================  Header content ======================== --> */}

        {/* Top Header start */}
        <TopHeader />
        {/* Top Header end */}

        {/* logo & menu  start */}
        <Header />
        {/* logo & menu  end */}

        {/* ========================  Photo Gallery ======================== */}
        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cormorant sectionTitle text-white mt-4">
                  Photo Gallery
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section">
          {/*Header*/}
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="mb2">
                  <img src="img/gallery/s1.webp" className="img-fluid" />
                </div>
                <div className="mb2">
                  <img src="img/gallery/s6.webp" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb2">
                  <img src="img/gallery/s2.webp" className="img-fluid" />
                </div>
                <div className="mb2">
                  <img src="img/gallery/s3.webp" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb2">
                  <img src="img/gallery/s5.webp" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb2">
                  <img src="img/gallery/s4.webp" className="img-fluid" />
                </div>
                <div className="mb2">
                  <img src="img/gallery/s7.webp" className="img-fluid" />
                </div>
              </div>
            </div>
            {/* <div class="row"> */}
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 text-center">
                <a
                  className="gallery__item fresco"
                  href="img/Re-upholster-chairs.jpg"
                  data-fresco-group="gallery"
                >
                  <img src="img/Re-upholster-chairs.jpg" alt="Display Re-upholster chairs" className="w-100" />
                  <br />
                  Re-upholster chairs
                </a>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 text-center">
                <a
                  className="gallery__item fresco"
                  href="img/Kitchen-cabinets.jpg"
                  data-fresco-group="gallery"
                >
                  <img src="img/Kitchen-cabinets.jpg" alt="Display Kitchen cabinets" className="w-100" />
                  <br />
                  Kitchen cabinets
                </a>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 text-center">
                <a
                  className="gallery__item fresco"
                  href="img/Dining-table-after-restoration.jpg"
                  data-fresco-group="gallery"
                >
                  <img src="img/Dining-table-after-restoration.jpg" alt="Display Table in Furniture" className="w-100" />
                  <br />
                  Dining table after
                </a>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 text-center">
                <a
                  className="gallery__item fresco"
                  href="img/Dining-table-before-restoration.jpg"
                  data-fresco-group="gallery"
                >
                  <img src="img/Dining-table-before-restoration.jpg" alt="Display Dining Table Before Restoration" className="w-100" />
                  <br />
                  Dining table before
                </a>
              </div>
            </div>
            {/* </div> */}
          </div>
        </section>

        {/* <!-- ================== Footer  ================== --> */}

        {/* Footer start */}

        <Footer />

        {/* Footer end */}
      </div>
    </>
  );
}
export default PhotoGalleryPage;
